// This list also needs to be configured server-side in /resource/include/config.php
const ImageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
const VideoExtensions = ['webm', 'mp4', 'mkv', 'mov'];

let HTMLExtensionList = "." + ImageExtensions.join(',.') + VideoExtensions.join(',.');

// Fetch data from endpoint.
async function fetchData(url) {
	try {
		const response = await fetch(url);
		const data = await response.json() || false;
		if (data.success === true) {
			return data;
		} else {
			return false;
		}
	} catch (err) {
		return false;
	}
}

// Post data to endpoint.
async function postData(url, json = null, callback = null) {
	try {
		const xhr = new XMLHttpRequest();
		xhr.open("POST", url, true);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && callback !== null) {
				const data = JSON.parse(xhr.responseText) || false;
				callback(data);
			}
		};
		xhr.send(json);
		return true;
	} catch (err) {
		return false;
	}
}

// Async sleep for x ms.
function sleep(ms) {
	console.log("Sleeping for " + ms + "ms.");
	return new Promise(resolve => setTimeout(resolve, ms));
}

// Convert number to comma-delimited string-number.
function formatNum(num) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
