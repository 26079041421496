// Init.
window.addEventListener("load", function() {
	initAlbum();
});


async function initAlbum() {
	const albumCanvas = document.getElementById("album_canvas");
	if (!albumCanvas) {
		return;
	}

	const albumId = window.location.pathname.split('/')[2];
	if (!albumId) {
		console.error("albumId not provided.")
		return;
	} else if (!Number(albumId)) {
		console.error("albumId is not a number.")
		return;
	}

	let albumCanvasNotice = document.getElementById("album_canvas_notice");
	let albumRequest = await fetchData("/api/get-album.php?id=" + albumId);
	if (!albumRequest || albumRequest.success === false) {
		console.error("Failed to fetch album data.")
		albumCanvasNotice.innerHTML = "<span style='color: red;'>Failed to load album.<br>Please let us know this issue occurred.</span>";
		return;
	}

	if (ImageExtensions.includes(albumRequest.imageList[0][3])) {
		albumCanvas.innerHTML = "<img loading='eager' class='gallery-item' src='" + albumRequest.imageList[0][0] + "'>";
	} else {
		albumCanvas.innerHTML = "<video controls autoplay muted class='gallery-item' src='" + albumRequest.imageList[0][0] + "'>";
	}

	albumCanvas.setAttribute("image_index", 0);
	albumCanvas.setAttribute("image_index_total", albumRequest.imageList.length - 1);

	fetchData("/api/media-view-counter.php?mediaId=" + albumRequest.imageList[0][0].split("/").pop().split(".")[0])

	const imageCounter = document.getElementById("image_counter");
	imageCounter.innerHTML = "<img height='20px' src='/resource/image/icon-camera.png'> " + 1 + "/" + albumRequest.imageList.length + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-eyeball.png'> " + albumRequest.imageList[0][1] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-download.png'> " + albumRequest.imageList[0][2];

	let buttonPrevious = document.getElementById("button_prev");
	if (albumRequest.imageList.length === 1) {
		buttonPrevious.remove();
	} else {
		buttonPrevious.addEventListener("click", function() {
			let imageCurrentIndex = albumCanvas.getAttribute("image_index");

			if (parseInt(imageCurrentIndex) === 0) {
				let newIndex = albumRequest.imageList.length - 1;

				if (ImageExtensions.includes(albumRequest.imageList[newIndex][3])) {
					albumCanvas.innerHTML = "<img loading='eager' class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				} else {
					albumCanvas.innerHTML = "<video controls autoplay muted class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				}
				albumCanvas.setAttribute("image_index", newIndex.toString());

				imageCounter.innerHTML = "<img height='20px' src='/resource/image/icon-camera.png'> " + (newIndex + 1) + "/" + albumRequest.imageList.length + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-eyeball.png'> " + albumRequest.imageList[newIndex][1] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-download.png'> " + albumRequest.imageList[newIndex][2];

				fetchData("/api/media-view-counter.php?mediaId=" + albumRequest.imageList[newIndex][0].split("/").pop().split(".")[0])
			} else {
				let newIndex = parseInt(imageCurrentIndex) - 1;

				if (ImageExtensions.includes(albumRequest.imageList[newIndex][3])) {
					albumCanvas.innerHTML = "<img loading='eager' class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				} else {
					albumCanvas.innerHTML = "<video controls autoplay muted class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				}

				albumCanvas.setAttribute("image_index", (newIndex).toString());

				imageCounter.innerHTML = "<img height='20px' src='/resource/image/icon-camera.png'> " + (newIndex + 1) + "/" + albumRequest.imageList.length + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-eyeball.png'> " + albumRequest.imageList[newIndex][1] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-download.png'> " + albumRequest.imageList[newIndex][2];

				fetchData("/api/media-view-counter.php?mediaId=" + albumRequest.imageList[newIndex][0].split("/").pop().split(".")[0])
			}
		});
	}

	let buttonNext = document.getElementById("button_next");
	if (albumRequest.imageList.length === 1) {
		buttonNext.remove();
	} else {
		buttonNext.addEventListener("click", function() {
			let imageCurrentIndex = albumCanvas.getAttribute("image_index");
			let imageListLength = albumCanvas.getAttribute("image_index_total");

			if (parseInt(imageCurrentIndex) === parseInt(imageListLength)) {
				let newIndex = 0;

				if (ImageExtensions.includes(albumRequest.imageList[newIndex][3])) {
					albumCanvas.innerHTML = "<img loading='eager' class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				} else {
					albumCanvas.innerHTML = "<video controls autoplay muted class='gallery-item' src='" + albumRequest.imageList[newIndex][0] + "'>";
				}

				albumCanvas.setAttribute("image_index", newIndex.toString());

				imageCounter.innerHTML = "<img height='20px' src='/resource/image/icon-camera.png'> " + (newIndex + 1) + "/" + albumRequest.imageList.length + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-eyeball.png'> " + albumRequest.imageList[newIndex][1] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-download.png'> " + albumRequest.imageList[newIndex][2];

				fetchData("/api/media-view-counter.php?mediaId=" + albumRequest.imageList[newIndex][0].split("/").pop().split(".")[0])
			} else {
				let newIndex = parseInt(imageCurrentIndex) + 1;


				if (ImageExtensions.includes(albumRequest.imageList[parseInt(imageCurrentIndex) + 1][3])) {
					albumCanvas.innerHTML = "<img loading='eager' class='gallery-item' src='" + albumRequest.imageList[parseInt(imageCurrentIndex) + 1][0] + "'>";
				} else {
					albumCanvas.innerHTML = "<video controls autoplay muted class='gallery-item' src='" + albumRequest.imageList[parseInt(imageCurrentIndex) + 1][0] + "'>";
				}

				albumCanvas.setAttribute("image_index", newIndex.toString());

				imageCounter.innerHTML = "<img height='20px' src='/resource/image/icon-camera.png'> " + (newIndex + 1) + "/" + albumRequest.imageList.length + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-eyeball.png'> " + albumRequest.imageList[newIndex][1] + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height='20px' src='/resource/image/icon-download.png'> " + albumRequest.imageList[newIndex][2];

				fetchData("/api/media-view-counter.php?mediaId=" + albumRequest.imageList[newIndex][0].split("/").pop().split(".")[0])
			}
		});
	}

	let buttonDownload = document.getElementById("button_down");
	buttonDownload.addEventListener("click", function() {
		const getSourceURIRegex = /src="\/media\/(.*?)"/gm;
		let currentlyViewedImage = getSourceURIRegex.exec(albumCanvas.innerHTML)[1];

		console.log("Starting download of file: /api/download.php?file=" + currentlyViewedImage);
		window.open("/api/download.php?file=" + currentlyViewedImage);
	});

	for (let i = 0; i < albumRequest.imageList.length; i++) {
		if (ImageExtensions.includes(albumRequest.imageList[i][3])) {
			console.log("Pre-loading image: " + albumRequest.imageList[i][0]);

			let preloadImage = new Image();
			preloadImage.src = albumRequest.imageList[i][0];

			let thisStep = 0;
			while (!preloadImage.complete) {
				if (thisStep > 20) {
					break;
				}
				await sleep(25);
				thisStep++;
			}
		}
	}
}
