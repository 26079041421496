// Init.
let albumList;
window.addEventListener("load", async function() {
	if (document.getElementById("content_master")) {
		await initMain();
	}
});

async function initMain() {
	let master = document.getElementById("content_master");
	if (master) {
		let response = await fetchData("/api/get-album-list.php");
		albumList = response.albums;
		let newHTML = "";

		for (let i = 0; i < albumList.length; i++) {
			let thisAlbum = albumList[i];

			let thisHTML = "<a href='/album/" + thisAlbum.id + "/" + thisAlbum.title.replaceAll(" ", "-") + "'><h1 id='album_title_" + thisAlbum.id + "'>" + thisAlbum.title + "</h1></a>";
			thisHTML += "<h2>Created on: " + thisAlbum.created + " PT</h2>";
			thisHTML += "<div id='" + thisAlbum.id + "'>"

			let thisMediaURI = thisAlbum["cover"]["uri"];
			let thisMediaExt = thisAlbum["cover"]["extension"];

			thisHTML += "<div class='album-container'>";
			if (ImageExtensions.includes(thisMediaExt)) {
				thisHTML += "	<a href='/album/" + thisAlbum.id + "/" + thisAlbum.title.replaceAll(" ", "-") + "'><img loading='eager' class='gallery-item' src='" + thisMediaURI + "'></a>";
			} else if (VideoExtensions.includes(thisMediaExt)) {
				thisHTML += "	<video controls autoplay muted class='gallery-item' src='" + thisMediaURI + "'>";
			}
			thisHTML += "</div>";

			thisHTML += "<div id='img_count_" + i + "'><img width='20px' src='/resource/image/icon-camera.png'> " + formatNum(thisAlbum["length"]) + "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img width='20px' src='/resource/image/icon-eyeball.png'> " + formatNum(thisAlbum.views) + "</div>";
			thisHTML += "<button class='button_view_album'>View Album</button>";

			thisHTML += "<hr>";

			thisHTML += "</div>"

			newHTML += thisHTML;
		}

		master.innerHTML = newHTML;

		let buttonsViewAlbum = document.getElementsByClassName("button_view_album");
		for (let i = 0; i < buttonsViewAlbum.length; i++) {
			let targetButton = buttonsViewAlbum[i];
			targetButton.addEventListener("click", function() {
				let thisParent = this.parentElement;
				window.location = "/album/" + thisParent.id + "/" + document.getElementById("album_title_" + thisParent.id).innerText.replaceAll(" ", "-");
			});
		}
	}
}
