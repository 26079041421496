// Init.
window.addEventListener("load", function() {
	if (document.getElementById("content_master_admin")) {
		initAdmin();
	}

	if (document.getElementById("content_master_admin_sign_in")) {
		initAdminSignIn();
	}

	let fileInput = document.getElementById("select_files");
	if (fileInput) {
		fileInput.addEventListener("change", function() {
			let statusOutput = document.getElementById("output");
			if (statusOutput) {
				statusOutput.innerHTML = "- " + fileInput.files.length + " file(s) ready to upload -"
			}
		});
	}
});

async function initAdminSignIn(message = null) {
	let master = document.getElementById("content_master_admin_sign_in");
	if (master) {
		let newHTML = "";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<input id='login_password' type='password' placeholder='PASSWORD'>";
		newHTML += "<br>";
		newHTML += "<button id='login_button'>Login</button>";
		newHTML += "<br>";
		if (message) {
			newHTML += "<text class='text-notice'>" + message + "</text>"
		}
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<br>";

		master.innerHTML = newHTML;

		let loginButton = document.getElementById("login_button");
		let loginPasswordField = document.getElementById("login_password")
		if (loginButton && loginPasswordField) {
			loginButton.addEventListener('click', async function() {
				await postData("/api/admin-login.php", JSON.stringify({"password": loginPasswordField.value}), initAdminSignInCallback);
			});
		}
	}
}

async function initAdminSignInCallback(data) {
	let master = document.getElementById("content_master_admin_sign_in");
	if (master) {
		if (data) {
			if (data.success) {
				window.location = "/admin";
			} else {
				await initAdminSignIn(data.message);
			}
		} else {
			await initAdminSignIn("Invalid request, try again.");
		}
	} else {
		console.error("Invalid DOM!");
	}
}

async function initAdmin() {
	let master = document.getElementById("content_master_admin");
	if (master) {
		let newHTML = "";
		newHTML += "<input id='album_name' autocomplete='off' maxlength='128' type='text' placeholder='Album Name'>";

		newHTML += "<br>";
		newHTML += "<button id='select_media'>Select Media</button>";
		newHTML += "<br>";
		newHTML += "<input id='is_private' type='checkbox' name='IsPrivate'>";
		newHTML += "<label for='IsPrivate'>Private Album</label>";
		newHTML += "<br>";
		newHTML += "<br>";
		newHTML += "<button class='button-green' id='create_album'>Create Album</button>";
		newHTML += "<br>";
		newHTML += "<p class='response' id='output'>< Waiting for input ></p>";
		newHTML += "<progress id='progress_bar' max='1' value='0.5' hidden></progress>";
		newHTML += "<input id='select_files' name='uploadMedia' type='file' accept='" + HTMLExtensionList + "' multiple hidden>";

		master.innerHTML = newHTML;

		let selectMediaButton = document.getElementById("select_media");
		if (selectMediaButton) {
			selectMediaButton.addEventListener("click", selectMediaItems);
		}

		let createAlbumButton = document.getElementById("create_album");
		if (createAlbumButton) {
			createAlbumButton.addEventListener("click", createAlbum);
		}
	}

	let albumListMaster = document.getElementById("content_master_albums");
	if (albumListMaster) {
		let response = await fetchData("/api/get-album-list.php");
		let albumList = response.albums;

		let thisTable = document.createElement("table");
		thisTable.id = "manage_albums";
		let thisTableRow0 = document.createElement("tr");
		let thisTableColumn0 = document.createElement("td");
		thisTableColumn0.innerHTML = "Title";
		let thisTableColumn1 = document.createElement("td");
		thisTableColumn1.innerHTML = "Date Created";
		let thisTableColumn2 = document.createElement("td");
		thisTableColumn2.innerHTML = "Private";
		let thisTableColumn3 = document.createElement("td");
		thisTableColumn3.innerHTML = "Actions (None of these work yet)";

		thisTableRow0.appendChild(thisTableColumn0);
		thisTableRow0.appendChild(thisTableColumn1);
		thisTableRow0.appendChild(thisTableColumn2);
		thisTableRow0.appendChild(thisTableColumn3);
		thisTable.appendChild(thisTableRow0);

		albumListMaster.innerHTML = null;
		albumListMaster.appendChild(thisTable);

		for (let i = 0; i < albumList.length; i++) {
			let thisAlbum = albumList[i];

			let thisRow = document.createElement("tr");

			let thisTableColumn0 = document.createElement("td");
			let thisTableColumn1 = document.createElement("td");
			let thisTableColumn2 = document.createElement("td");
			let thisTableColumn3 = document.createElement("td");

			thisTableColumn0.innerHTML = thisAlbum.title;
			thisTableColumn1.innerHTML = thisAlbum.created;
			thisTableColumn2.innerHTML = thisAlbum.private;
			thisTableColumn3.innerHTML = "<button class='button-yellow'>Edit Title</button><button class='button-yellow'>Edit Gallery</button><button class='button-red'>Delete Album</button><button>Toggle Private</button>";

			thisRow.appendChild(thisTableColumn0);
			thisRow.appendChild(thisTableColumn1);
			thisRow.appendChild(thisTableColumn2);
			thisRow.appendChild(thisTableColumn3);

			thisTable.appendChild(thisRow);
		}
	}
}

// Select media query.
function selectMediaItems() {
	let fileInput = document.getElementById("select_files");
	if (fileInput) {
		fileInput.click();
	}
}

// Create album.
async function createAlbum() {
	let outputText = document.getElementById("output");
	let targetFilesInput = document.getElementById("select_files");
	let albumNameInput = document.getElementById("album_name");
	let isPrivateInput = document.getElementById("is_private");

	if (albumNameInput && targetFilesInput && isPrivateInput && targetFilesInput.files.length > 0 && albumNameInput.value.length > 0) {
		let files = targetFilesInput.files;
		let progressBar = document.getElementById("progress_bar");
		progressBar.hidden = false;
		outputText.innerHTML = "Uploading..";

		let formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			formData.append("files[]", files[i]);
		}
		formData.append("albumName", albumNameInput.value);
		formData.append("isPrivate", isPrivateInput.checked)

		let xhr = new XMLHttpRequest();
		xhr.open('POST', "/api/upload.php");

		xhr.upload.addEventListener("progress", function(e) {
			let pctComplete = ((e.loaded / e.total) || 1);
			if (pctComplete === 1) {
				outputText.innerHTML = "<p>- Waiting for server -</p>";
			} else if (pctComplete !== 1) {
				progressBar.value = pctComplete;
			}
		});

		xhr.addEventListener('timeout', function() {
			outputText.innerHTML = "Upload failed (timeout).";
		});

		xhr.addEventListener('readystatechange', function() {
			if (xhr.readyState === 4) {
				try {
					console.log(xhr.responseText);
				} catch (error) {
					console.error(error);
				}
			}
		});

		xhr.send(formData);

		console.log("Waiting for upload to finish & server response..");
		while (xhr.readyState !== 4) {
			await sleep(50);
		}

		outputText.innerHTML = "Upload complete.";
		progressBar.hidden = true;
		progressBar.value = 0;

		targetFilesInput.value = null;
	} else {
		if (albumNameInput.value.length === 0) {
			outputText.innerHTML = "You must give an album name.";
		} else {
			outputText.innerHTML = "You must select at least one file to add to this album.";
		}
	}
}
